import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { Layout } from '../components/Layout';
import styled from 'styled-components';
import { InlineLink } from '../elements/InlineLink';
import { TiArrowLeftOutline, TiArrowRightOutline } from 'react-icons/ti';
import { FadedText } from '../elements/FadedText';

// code blocks
const BlogPostTemplate = ({ data, pageContext }) => {
  const { frontmatter, body, fields } = data.mdx;
  const { previous, next } = pageContext;
  return (
    <Layout>
      <TitleBar>
        <h2 style={{ fontFamily: 'Arvo' }}>{frontmatter.title}</h2>
        <FadedText
          style={{
            display: 'flex',
            alignSelf: 'center',
          }}
        >
          {fields.readingTime.text}
          <br />
          {frontmatter.date}
        </FadedText>
      </TitleBar>
      <MDXRenderer>{body}</MDXRenderer>
      <div
        style={{
          display: 'flex',
          justifyContent: previous ? 'space-between' : 'flex-end',
          alignItems: 'center',
        }}
      >
        {previous && (
          <p>
            <FadedText>Previous</FadedText>
            <br />
            <InlineLink
              to={previous.fields.slug}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TiArrowLeftOutline size='1.2em' style={{ marginRight: '1em' }} />
              {previous.frontmatter.title}
            </InlineLink>
          </p>
        )}
        {next && (
          <p>
            <FadedText style={{ float: 'right' }}>Next</FadedText>
            <br />
            <InlineLink
              to={next.fields.slug}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {next.frontmatter.title}
              <TiArrowRightOutline size='1.2em' style={{ marginLeft: '1em' }} />
            </InlineLink>
          </p>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "DD-MMM-YY")
      }
    }
  }
`;

export default BlogPostTemplate;

// Styles
const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;
